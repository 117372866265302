.navigation{
  width:100%;
  position:fixed;
  background-color: white;
  z-index:5;
  &__layout{
    @include css-reset;
    display:flex;
    list-style-type: none;
    border-bottom: 1px solid lighten($primary-color,80%);
  }
  &__item{
    padding-top:20px;
    padding-bottom:20px;
    margin-left:4%;
    margin-right:4%;
    font-size: map-get($type-ramp,md);
    color: $secondary-color;
    cursor:pointer;
    transition:.35s;
    &:hover{
      color:$primary-color;
    }
    @include breakpoint(large){
      margin-left:0;
      font-size: map-get($type-ramp,md-mobile)
    }
  }
  &__item--title{
    margin-right:auto;
    margin-left:4%;
    color:$primary-color;
  }
}
