.about{
  position:fixed;
  z-index:10;
  width:100%;
  height:100vh;
  left:0;
  top:0;
  overflow:scroll;
  -webkit-overflow-scrolling: touch;
  &--visible{
    visibility: visible;
    transition:.45s;
    background-color: rgba(255,255,255,1);
  }
  &--hidden{
    visibility: hidden;
    transition:.45s;
    background-color: rgba(0,0,0,0);
  }
  &__close{
    position:fixed;
    right:0px;
    top:0px;
    cursor:pointer;
    transition:.25s;
    padding:20px;
    &:hover{
      transform:rotate(90deg);
      transition:.25s;
      opacity:.5;
    }
  }
}
.aboutContent{
  display:flex;
  max-width:1600px;
  padding-top:4%;
  margin:auto;
  flex-flow: row wrap;
  &__intro{
    // display:flex;
    // flex-flow:row wrap;
    width:92%;
    height:auto;
    margin:0 4%;
    order:2;
    @include breakpoint(large){
      order:1;
      width:60%;
    }
  }
  &__img{
    order:1;
    width:92%;
    margin:8% 4% 4%;
    margin-left:auto;
    @include breakpoint(large){
      width:28%;
      order:2;
      margin-top:4%;
    }
    img{
      max-width:100%;
    }
  }
  &__title{
    @include css-reset;
    width:100%;
    color:$primary-color;
    font-size:map-get($type-ramp,md-mobile);
    margin:4% 0;
    @include breakpoint(medium){
      margin-bottom:map-get($margin-ramp,xl);
      font-size:map-get($type-ramp,lg);
    }
    // @include breakpoint(large){
    //   width:46%;
    // }
  }
  &__quote{
    font-size:map-get($type-ramp,md-mobile);
    color:$primary-color;
    font-family:$noto;
    // margin-right:4%;
    // margin-left:4%;
    margin-bottom:map-get($margin-ramp,md);
    line-height: map-get($leading-ramp,m);
    @include breakpoint(medium){
      font-size:map-get($type-ramp,lg);
    }
    @include breakpoint(large){
      margin-top:map-get($margin-ramp,xxxl)*2.5;
      font-size:map-get($type-ramp,xl);
      // max-width:80%;
    }
  }
  &__description{
    @include css-reset;
    display:flex;
    flex-flow:row wrap;
    color:$secondary-color;
    font-size:map-get($type-ramp,md);
    margin-bottom:map-get($margin-ramp,lg);
    line-height:map-get($leading-ramp,m);
  }
  .description__copy{
    width:100%;
    margin-right:0;
    @include breakpoint(large){
      width:46%;
      margin-right:4%;
    }
  }
  &__link{
    @include css-reset;
    text-decoration:none;
    color:$highlight-color;
    font-size:map-get($type-ramp,sm)
  }
}
