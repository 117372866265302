@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Serif+TC:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Serif&display=swap);
:root {
  font-size: 10px;
  font-family: 'Roboto', sans-serif; }

.col-1 {
  margin-left: 4%;
  -webkit-flex: 0 1 92%;
          flex: 0 1 92%; }
  @media screen and (min-width: 768px) {
    .col-1 {
      -webkit-flex: 0 1 12%;
              flex: 0 1 12%; } }

.col-2 {
  margin-left: 4%;
  -webkit-flex: 0 1 92%;
          flex: 0 1 92%; }
  @media screen and (min-width: 768px) {
    .col-2 {
      -webkit-flex: 0 1 28%;
              flex: 0 1 28%; } }

.col-3 {
  margin-left: 4%;
  -webkit-flex: 0 1 92%;
          flex: 0 1 92%; }
  @media screen and (min-width: 768px) {
    .col-3 {
      -webkit-flex: 0 1 44%;
              flex: 0 1 44%; } }

.col-4 {
  margin-left: 4%;
  -webkit-flex: 0 1 92%;
          flex: 0 1 92%; }
  @media screen and (min-width: 768px) {
    .col-4 {
      -webkit-flex: 0 1 60%;
              flex: 0 1 60%; } }

.col-5 {
  margin-left: 4%;
  -webkit-flex: 0 1 92%;
          flex: 0 1 92%; }
  @media screen and (min-width: 768px) {
    .col-5 {
      -webkit-flex: 0 1 76%;
              flex: 0 1 76%; } }

.col-6 {
  margin-left: 4%;
  -webkit-flex: 0 1 92%;
          flex: 0 1 92%; }
  @media screen and (min-width: 768px) {
    .col-6 {
      -webkit-flex: 0 1 92%;
              flex: 0 1 92%; } }

.grid {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  max-width: 1600px;
  margin: auto;
  position: relative;
  z-index: 1; }
  .grid .grid__quote {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    max-width: 100%;
    font-size: 2rem;
    color: #0C0D0D;
    font-family: "Noto Serif TC", serif;
    margin-top: 60px;
    margin-bottom: 60px;
    line-height: 1.5em; }
    .grid .grid__quote.quote__intro {
      color: #6e7777;
      margin-top: 180px; }
    .grid .grid__quote .quote__intro--title {
      color: #0C0D0D; }
    @media screen and (min-width: 768px) {
      .grid .grid__quote {
        font-size: 2.4rem;
        margin-bottom: 60px; } }
    @media screen and (min-width: 1024px) {
      .grid .grid__quote {
        font-size: 3.4rem;
        margin-top: 80px;
        margin-bottom: 80px; } }
  .grid .grid__item {
    cursor: pointer;
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0);
    height: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column wrap;
            flex-flow: column wrap; }
    @media screen and (min-width: 768px) {
      .grid .grid__item {
        margin-top: 60px;
        margin-bottom: 60px; } }
    .grid .grid__item.grid__item--empty {
      display: none; }
      @media screen and (min-width: 768px) {
        .grid .grid__item.grid__item--empty {
          display: block; } }
  .grid .item__title {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    font-size: 2rem;
    margin-bottom: 10px;
    color: #0C0D0D; }
    @media screen and (min-width: 1024px) {
      .grid .item__title {
        font-size: 2.4rem; } }
  .grid .item__subtitle {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    font-size: 1.6rem;
    color: #6e7777;
    line-height: 1.5em; }
  .grid .item__image {
    cursor: pointer; }
  .grid .item__image img {
    max-width: 100%;
    height: auto;
    margin-bottom: 30px;
    transition: .25s;
    opacity: 1; }
    .grid .item__image img:hover {
      opacity: .5; }

.fullscreen {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  overflow: scroll;
  -webkit-overflow-scrolling: touch; }
  .fullscreen--visible {
    visibility: visible;
    transition: .45s;
    background-color: white; }
    @media screen and (min-width: 768px) {
      .fullscreen--visible {
        background-color: white; } }
  .fullscreen--hidden {
    visibility: hidden;
    transition: .45s;
    background-color: rgba(0, 0, 0, 0); }
  .fullscreen__close {
    position: fixed;
    right: 0px;
    top: 0px;
    cursor: pointer;
    transition: .25s;
    padding: 20px; }
    .fullscreen__close:hover {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      transition: .25s;
      opacity: .5; }

.fullscreenContent {
  display: -webkit-flex;
  display: flex;
  max-width: 1600px;
  margin: auto;
  margin-top: 5%;
  -webkit-align-items: start;
          align-items: start;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap; }
  @media screen and (min-width: 1024px) {
    .fullscreenContent {
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap; } }
  .fullscreenContent__image {
    margin-bottom: 80px; }
    @media screen and (min-width: 1024px) {
      .fullscreenContent__image {
        margin-left: auto;
        margin-right: auto; } }
    .fullscreenContent__image img {
      max-width: 96%; }
  .fullscreenContent__title {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    color: #0C0D0D;
    font-size: 2.4rem;
    margin-bottom: 20px; }
  .fullscreenContent__description {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    color: #6e7777;
    font-size: 1.4rem;
    margin-bottom: 20px;
    line-height: 1.5em; }
    @media screen and (min-width: 1024px) {
      .fullscreenContent__description {
        margin-bottom: 40px; } }
  .fullscreenContent__link {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    display: block;
    text-decoration: underline;
    color: #2E998D;
    font-size: 1.4rem;
    margin-bottom: 40px; }

.navigation {
  width: 100%;
  position: fixed;
  background-color: white;
  z-index: 5; }
  .navigation__layout {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    display: -webkit-flex;
    display: flex;
    list-style-type: none;
    border-bottom: 1px solid #d7dada; }
  .navigation__item {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 4%;
    margin-right: 4%;
    font-size: 1.6rem;
    color: #6e7777;
    cursor: pointer;
    transition: .35s; }
    .navigation__item:hover {
      color: #0C0D0D; }
    @media screen and (min-width: 1024px) {
      .navigation__item {
        margin-left: 0;
        font-size: 2rem; } }
  .navigation__item--title {
    margin-right: auto;
    margin-left: 4%;
    color: #0C0D0D; }

.about {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  overflow: scroll;
  -webkit-overflow-scrolling: touch; }
  .about--visible {
    visibility: visible;
    transition: .45s;
    background-color: white; }
  .about--hidden {
    visibility: hidden;
    transition: .45s;
    background-color: rgba(0, 0, 0, 0); }
  .about__close {
    position: fixed;
    right: 0px;
    top: 0px;
    cursor: pointer;
    transition: .25s;
    padding: 20px; }
    .about__close:hover {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      transition: .25s;
      opacity: .5; }

.aboutContent {
  display: -webkit-flex;
  display: flex;
  max-width: 1600px;
  padding-top: 4%;
  margin: auto;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap; }
  .aboutContent__intro {
    width: 92%;
    height: auto;
    margin: 0 4%;
    -webkit-order: 2;
            order: 2; }
    @media screen and (min-width: 1024px) {
      .aboutContent__intro {
        -webkit-order: 1;
                order: 1;
        width: 60%; } }
  .aboutContent__img {
    -webkit-order: 1;
            order: 1;
    width: 92%;
    margin: 8% 4% 4%;
    margin-left: auto; }
    @media screen and (min-width: 1024px) {
      .aboutContent__img {
        width: 28%;
        -webkit-order: 2;
                order: 2;
        margin-top: 4%; } }
    .aboutContent__img img {
      max-width: 100%; }
  .aboutContent__title {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    width: 100%;
    color: #0C0D0D;
    font-size: 2rem;
    margin: 4% 0; }
    @media screen and (min-width: 768px) {
      .aboutContent__title {
        margin-bottom: 60px;
        font-size: 2.4rem; } }
  .aboutContent__quote {
    font-size: 2rem;
    color: #0C0D0D;
    font-family: "Noto Serif TC", serif;
    margin-bottom: 30px;
    line-height: 1.5em; }
    @media screen and (min-width: 768px) {
      .aboutContent__quote {
        font-size: 2.4rem; } }
    @media screen and (min-width: 1024px) {
      .aboutContent__quote {
        margin-top: 300px;
        font-size: 3.4rem; } }
  .aboutContent__description {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    color: #6e7777;
    font-size: 1.6rem;
    margin-bottom: 40px;
    line-height: 1.5em; }
  .aboutContent .description__copy {
    width: 100%;
    margin-right: 0; }
    @media screen and (min-width: 1024px) {
      .aboutContent .description__copy {
        width: 46%;
        margin-right: 4%; } }
  .aboutContent__link {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    text-decoration: none;
    color: #2E998D;
    font-size: 1.4rem; }

.footer {
  max-width: 1600px;
  margin: auto; }
  .footer .footer__container {
    margin: 4% 4% 8% 4%; }
  .footer .footer__contact {
    font-size: 2rem;
    font-family: "Noto Serif TC", serif;
    line-height: 1.6em;
    text-align: center;
    width: 100%;
    margin: auto;
    margin-bottom: 60px; }
    .footer .footer__contact a {
      text-decoration: none;
      color: #2E998D;
      transition: .25s ease-out; }
    .footer .footer__contact a:hover {
      color: #0C0D0D; }
    @media screen and (min-width: 768px) {
      .footer .footer__contact {
        font-size: 2.4rem;
        width: 100%; } }
    @media screen and (min-width: 1024px) {
      .footer .footer__contact {
        width: 900px;
        font-size: 3.4rem;
        margin-bottom: 120px; } }
  .footer .footer__byline {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-align-items: baseline;
            align-items: baseline;
    list-style-type: none;
    font-size: 1.6rem;
    line-height: 1.6em;
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: 1px solid rgba(0, 0, 0, 0.2); }
  .footer .footer__bio {
    width: 100%; }
    @media screen and (min-width: 768px) {
      .footer .footer__bio {
        width: 350px; } }
  .footer .footer__social {
    padding: 0;
    margin-left: 0;
    margin-top: 30px;
    list-style-type: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row wrap;
            flex-direction: row wrap; }
    @media screen and (min-width: 768px) {
      .footer .footer__social {
        margin-top: 0;
        margin-left: auto; } }
    .footer .footer__social li {
      transition: .25s ease-out;
      cursor: pointer; }
    .footer .footer__social li:hover {
      color: #2E998D; }
    .footer .footer__social li:nth-child(2n),
    .footer .footer__social li:last-child {
      margin-left: 40px; }
  .footer .footer__copyright {
    font-size: 1.6rem;
    color: #6e7777; }

body {
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

a {
  text-decoration: none;
  color: inherit; }

