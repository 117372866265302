

.fullscreen{
  position:fixed;
  z-index:10;
  width:100%;
  height:100vh;
  left:0;
  top:0;
  overflow:scroll;
  -webkit-overflow-scrolling: touch;
  &--visible{
    visibility: visible;
    transition:.45s;
    background-color: rgba(255,255,255,1);
    @include breakpoint(medium){
      background-color: rgba(255,255,255,1);
    }
  }
  &--hidden{
    visibility: hidden;
    transition:.45s;
    background-color: rgba(0,0,0,0);
  }
  &__close{
    position:fixed;
    right:0px;
    top:0px;
    cursor:pointer;
    transition:.25s;
    padding:20px;
    &:hover{
      transform:rotate(90deg);
      transition:.25s;
      opacity:.5;
    }
  }
}
.fullscreenContent{
  display:flex;
  max-width:1600px;
  margin:auto;
  margin-top:5%;
  align-items: start;
  flex-flow:column wrap;
  @include breakpoint(large){
    flex-flow:row wrap;
  };
  &__image{
    margin-bottom:map-get($margin-ramp,xxl);
    @include breakpoint(large){
      margin-left:auto;
      margin-right:auto;
    }
    img{
      max-width:96%;
    }
  }
  &__words{
  }
  &__title{
    @include css-reset;
    color:$primary-color;
    font-size:map-get($type-ramp,lg);
    margin-bottom:map-get($margin-ramp,sm);
  }
  &__description{
    @include css-reset;
    color:$secondary-color;
    font-size:map-get($type-ramp,sm);
    margin-bottom:map-get($margin-ramp,sm);
    line-height:map-get($leading-ramp,m);
    @include breakpoint(large){
      margin-bottom:map-get($margin-ramp,lg);
    }
  }
  &__link{
    @include css-reset;
    display:block;
    text-decoration:underline;
    color:$highlight-color;
    font-size:map-get($type-ramp,sm);
    margin-bottom:map-get($margin-ramp,lg);
  }
}
