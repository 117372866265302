$grid-margin: 4%;
$col-widths: (
  1: 12%,
  2: 28%,
  3: 44%,
  4: 60%,
  5: 76%,
  6: 92%
);

$grid__cols: 6;

@for $i from 1 through $grid__cols{
  .col-#{$i}{
    margin-left:$grid-margin;
    flex:0 1 92%;
    @include breakpoint(medium){
      flex:0 1 map-get($col-widths,$i);
    };
  }
};

.grid{
  display:flex;
  flex-flow: row wrap;
  align-items: flex-start;
  max-width:1600px;
  margin:auto;
  position:relative;
  z-index:1;
  .grid__quote{
    @include css-reset;
    max-width:100%;
    font-size:map-get($type-ramp,md-mobile);
    color:$primary-color;
    font-family:$noto;
    margin-top:map-get($margin-ramp,xl);
    margin-bottom:map-get($margin-ramp,xl);
    line-height: map-get($leading-ramp,m);
    &.quote__intro{
      color:$secondary-color;
      margin-top:map-get($margin-ramp,xxxl)*1.5;
    }
    .quote__intro--title{
      color:$primary-color;
    }
    @include breakpoint(medium){
      font-size:map-get($type-ramp,lg);
      margin-bottom:map-get($margin-ramp,xl);
    }
    @include breakpoint(large){
      font-size:map-get($type-ramp,xl);
      margin-top:map-get($margin-ramp,xxl);
      margin-bottom:map-get($margin-ramp,xxl);
    }
  }
  .grid__item{
    cursor:pointer;
    max-width: 100%;
    background-color: rgba(0,0,0,0);
    height:auto;
    margin-top:map-get($margin-ramp,md);
    margin-bottom:map-get($margin-ramp,md);
    display:flex;
    flex-flow:column wrap;
    @include breakpoint(medium){
      margin-top:map-get($margin-ramp,xl);
      margin-bottom:map-get($margin-ramp,xl);
    }
    &.grid__item--empty{
      display:none;
      @include breakpoint(medium){
        display:block;
      }
    }
  }
  .item__title{
    @include css-reset;
    font-size:map-get($type-ramp,md-mobile);
    margin-bottom:map-get($margin-ramp,xxs);
    color:$primary-color;
    @include breakpoint(large){
      font-size:map-get($type-ramp,lg);
    }
  }
  .item__subtitle{
    @include css-reset;
    font-size:map-get($type-ramp,md);
    color:$secondary-color;
    line-height:map-get($leading-ramp,m);
  }
  .item__image{
    cursor:pointer;
  }
  .item__image img{
    max-width:100%;
    height:auto;
    margin-bottom:map-get($margin-ramp,md);
    @include image-states;
  }
}
